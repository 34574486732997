export const ADDEVENT_PAYMENT_STATUSES = {
  AWAIT: 'AWAIT',
  WITHOUT_PAYMENT: 'WITHOUT_PAYMENT',
  PAID: 'PAID',
  UPON_RECEIPT: 'UPON_RECEIPT',
}

export const ADDEVENT_PAYMENT_STATUSES_DISPLAY = {
  [ADDEVENT_PAYMENT_STATUSES.AWAIT]: 'Ожидает оплаты',
  [ADDEVENT_PAYMENT_STATUSES.WITHOUT_PAYMENT]: 'Без оплаты',
  [ADDEVENT_PAYMENT_STATUSES.PAID]: 'Оплачено',
  [ADDEVENT_PAYMENT_STATUSES.UPON_RECEIPT]: 'При получении',
}
