<template>
  <div :class="$style.calendar">
    <p :class="$style.statusLabel">Статусы заказа:</p>
    <div :class="$style.statuses">
      <span
        v-for="status in Object.keys($options.ADDEVENT_ORDER_STATUSES)"
        :key="status"
        :style="`background-color: ${$options.ADDEVENT_ORDER_STATUSES_COLORS[status]}`"
        :class="$style.status"
      >
        {{ $options.ADDEVENT_ORDER_STATUSES_DISPLAY[status] }}
      </span>
    </div>
    <div :class="$style.filters">
      <el-button
        type="primary"
        :class="$style.openButton"
        @click="isFiltersOpen = !isFiltersOpen"
        >Фильтры</el-button
      >
      <Dropdown
        :isOpenDropDown="isFiltersOpen"
        title="Фильтры главного каталога"
        :class="$style.dropdown"
        @submitFilters="submitFilters()"
        @resetFilters="resetFilters()"
      >
        <div :class="$style.filter">
          <p>Cтатус заказа:</p>
          <el-select
            v-model="filters.status"
            :class="$style.select"
            placeholder="Выберите статус заказа"
          >
            <el-option
              v-for="status in Object.keys($options.ADDEVENT_ORDER_STATUSES)"
              :key="status"
              :label="$options.ADDEVENT_ORDER_STATUSES_DISPLAY[status]"
              :value="status"
            >
            </el-option>
          </el-select>
        </div>
        <div :class="$style.filter">
          <p>Тип доставки для получения:</p>
          <el-select
            v-model="filters.deliveryType"
            :class="$style.select"
            placeholder="Выберите тип доставки"
          >
            <el-option
              v-for="item in $options.DELIVERY_TYPES_LIST"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div :class="$style.filter">
          <p>Способ оплаты:</p>
          <el-select
            v-model="filters.paymentType"
            placeholder="Выберите способ оплаты"
            :class="$style.select"
          >
            <el-option
              v-for="item in $options.PAYMENTS_TYPES_LIST"
              :key="item.id"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div :class="$style.filter">
          <p>Кол-во отображаемых событий в день:</p>
          <el-input-number
            v-model="maxEventPerDay"
            :min="1"
            :max="15"
            @change="
              $refs.calendar.getApi().setOption('dayMaxEvents', maxEventPerDay)
            "
          ></el-input-number>
        </div>
      </Dropdown>
    </div>
    <FullCalendar ref="calendar" :options="calendarOptions" />
    <el-drawer
      :title="`Информация о заказе ${order?.name}`"
      :visible="isModalOpen"
      size="40%"
      @close="handleCloseModal"
    >
      <div v-if="order" :class="$style.order">
        <div :class="$style.info">
          <span :class="$style.label">Статус заказа:</span>
          <span
            :class="$style.value"
            :style="`background-color: ${
              $options.ADDEVENT_ORDER_STATUSES_COLORS[order?.status]
            }`"
          >
            {{ $options.ADDEVENT_ORDER_STATUSES_DISPLAY[order?.status] }}</span
          >
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Тип доставки для получения:</span>

          <span :class="$style.value">
            {{ $options.DELIVERY_TYPE_DISPLAY[order?.deliveryType] }}</span
          >
        </div>

        <div :class="$style.info">
          <span :class="$style.label">Тип доставки для возврата:</span>
          <span :class="$style.value">
            {{ $options.DELIVERY_TYPE_DISPLAY[order?.deliveryTypeFrom] }}</span
          >
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Способ оплаты:</span>
          <span :class="$style.value">
            {{ $options.PAYMENT_TYPES_DISPLAY[order?.paymentType] ?? '' }}</span
          >
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Статус оплаты:</span>
          <span :class="$style.value">
            {{
              $options.ADDEVENT_PAYMENT_STATUSES_DISPLAY[order?.paymentStatus]
            }}</span
          >
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Начало аренды:</span>
          <span :class="$style.value"> {{ order?.startAt }}</span>
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Окончание аренды:</span>
          <span :class="$style.value"> {{ order?.finishAt }}</span>
        </div>
        <div v-if="isDelivery(order?.deliveryType)" :class="$style.info">
          <span :class="$style.label">Адрес куда доставить:</span>
          <span :class="$style.value"> {{ order?.deliveryAddressTo }}</span>
        </div>
        <div v-if="isDelivery(order?.deliveryTypeFrom)" :class="$style.info">
          <span :class="$style.label">Адрес от куда забрать:</span>
          <span :class="$style.value"> {{ order?.deliveryAddressFrom }}</span>
        </div>
        <div
          v-if="
            (isDelivery(order?.deliveryType) ||
              isDelivery(order?.deliveryTypeFrom)) &&
            order?.deliveryPrice
          "
          :class="$style.info"
        >
          <span :class="$style.label">Цена доставки:</span>
          <span :class="$style.value"> {{ order?.deliveryPrice }}</span>
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Имя получателя:</span>
          <span :class="$style.value"> {{ order?.client?.name }}</span>
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Телефон получателя:</span>
          <span :class="$style.value"> {{ order?.client?.phone }}</span>
        </div>
        <div :class="$style.info">
          <span :class="$style.label">Почта получателя:</span>
          <span :class="$style.value"> {{ order?.client?.email }}</span>
        </div>
        <div v-if="order?.comment" :class="$style.info">
          <span :class="$style.label">Комментарий:</span>
          <span :class="$style.value"> {{ order?.comment }}</span>
        </div>

        <p :class="$style.tableName">Состав заказа:</p>
        <el-table
          :data="order?.products ?? []"
          :class="$style.table"
          style="width: 100%"
        >
          <el-table-column prop="name" label="Название"> </el-table-column>
          <el-table-column prop="quantity" label="Количество">
          </el-table-column>
          <el-table-column
            prop="basicPricePerDay"
            :label="`Цена за  первые ${3} суток`"
          >
          </el-table-column>
          <el-table-column
            prop="additionalPricePerDay"
            label="Цена за последующие сутки"
          >
          </el-table-column>
        </el-table>
        <div :class="[$style.info, $style.total]">
          <span :class="$style.label">Общая сумма:</span>
          <span :class="$style.value"> {{ order?.price }}</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import delivery from '@/delivery'
import { formatDate } from '@/helpers/index.js'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import ruLocale from '@fullcalendar/core/locales/ru'
import {
  ADDEVENT_ORDER_STATUSES_COLORS,
  ADDEVENT_ORDER_STATUSES,
  ADDEVENT_ORDER_STATUSES_DISPLAY,
} from '@/constants/orderStatuses'

import {
  PAYMENT_TYPES_DISPLAY,
  PAYMENTS_TYPES_LIST,
} from '@/constants/paymentTypes'

import {
  ADDEVENT_PAYMENT_STATUSES_DISPLAY,
  ADDEVENT_PAYMENT_STATUSES,
} from '@/constants/paymentStatuses'
import {
  DELIVERY_TYPE_DISPLAY,
  DELIVERY_TYPE,
  DELIVERY_TYPES_LIST,
} from '@/constants/deliveryType'
import Dropdown from '@/components/atoms/Dropdown'

export default {
  PAYMENTS_TYPES_LIST,
  PAYMENT_TYPES_DISPLAY,
  DELIVERY_TYPE_DISPLAY,
  DELIVERY_TYPE,
  DELIVERY_TYPES_LIST,
  ADDEVENT_PAYMENT_STATUSES,
  ADDEVENT_PAYMENT_STATUSES_DISPLAY,
  ADDEVENT_ORDER_STATUSES_COLORS,
  ADDEVENT_ORDER_STATUSES,
  ADDEVENT_ORDER_STATUSES_DISPLAY,
  components: {
    FullCalendar,
    Dropdown,
  },
  data() {
    return {
      isModalOpen: false,
      order: null,
      isFiltersOpen: false,
      startDate: new Date(),
      endDate: new Date(),
      filters: {
        paymentStatus: null,
        status: null,
        deliveryType: null,
        paymentType: null,
        deliveryTypeFrom: null,
      },
      maxEventPerDay: 3,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
        headerToolbar: {
          right: '',
          left: 'prev,next today',
          center: 'title',
        },
        dayMaxEvents: 3,
        weekends: true,
        datesSet: this.handleDateSelect,
        hiddenDays: [],
        locale: ruLocale,
        timeZone: 'Europe/Moscow',
        eventTextColor: '#000000',
        events: [],
        eventClick: this.handleEventClick,
        height: 900,
      },
    }
  },
  methods: {
    async getOrders() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        page: 1,
        limit: 500,
        startAt: this.startDate ?? '',
        finishAt: this.endDate ?? '',
      }

      if (this.filters.status) {
        query.status = this.filters.status
      }
      if (this.filters.paymentType) {
        query.paymentType = this.filters.paymentType
      }

      if (this.filters.deliveryType) {
        query.deliveryType = this.filters.deliveryType
      }

      const { value, error } =
        await delivery.AddeventServiceCore.OrdersActions.getList(query)

      loading.close()

      if (error) return

      this.calendarOptions.events = value.data?.map((e) => ({
        title: e?.name,
        start: new Date(e?.startAt),
        end: new Date(e?.finishAt),
        allDay: true,
        id: e?.id,
        color: ADDEVENT_ORDER_STATUSES_COLORS[e?.status],
        type: e?.type,
      }))
    },
    async handleDateSelect(selectInfo) {
      this.startDate = new Date(selectInfo?.startStr)
      this.endDate = new Date(selectInfo?.endStr)
      this.resetStateFilters()

      await this.getOrders()
    },
    async handleCloseModal() {
      this.order = null
      this.isModalOpen = false
      const html = document.querySelector('html')
      html.style.overflowY = 'auto'
    },
    async submitFilters() {
      this.isFiltersOpen = false

      await this.getOrders()
    },
    resetStateFilters() {
      this.filters = {
        status: null,
        deliveryType: null,
        paymentType: null,
      }
    },
    async resetFilters() {
      this.isFiltersOpen = false

      this.resetStateFilters()
      await this.getOrders()
    },
    async handleEventClick(e) {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.OrdersActions.getOne(e?.event?.id)

      loading.close()

      if (error) return

      this.order = {
        ...value,
        startAt: this.formatDate(value.startAt),
        finishAt: this.formatDate(value.finishAt),
      }

      this.isModalOpen = true
      const html = document.querySelector('html')
      html.style.overflowY = 'hidden'
    },
    isDelivery(type) {
      return type === DELIVERY_TYPE.DELIVERY
    },
    formatDate(date) {
      return formatDate(date)
    },
  },
}
</script>
<style>
.fc-event {
  cursor: pointer;
}
</style>
<style lang="scss" module>
.calendar {
  margin: 1rem 0;

  .statusLabel {
    @include H100;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .statuses {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    .status {
      padding: 0.5rem 1rem;
    }
  }

  .filters {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    .openButton {
      margin-bottom: 1rem;
    }
    .dropdown {
      left: 7rem;
      top: 0;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .filter {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 2fr;
          gap: 0.5rem;
        }
      }
    }
  }
  .order {
    padding: 0 1.25rem;
    display: grid;
    gap: 0.5rem;
    .info {
      .label {
        @include bold-default;
      }
      .value {
        @include text;
      }

      &.total {
        .label,
        .value {
          @include title;
        }
      }
    }

    .tableName {
      margin: 1rem 0 0 0;
      color: $blue;
      @include H200;
    }
    .table {
      margin-bottom: 1rem;
    }
  }

  @include custom(1800) {
    .statusLabel {
      text-align: start;
    }
    .statuses {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      justify-content: start;
    }
  }
}
</style>
