export const PAYMENT_TYPES = {
  CASH: 'CASH',
  CARD: 'CARD',
  BILL: 'BILL',
}

export const PAYMENT_TYPES_DISPLAY = {
  CASH: 'Наличными',
  CARD: 'Картой',
  BILL: 'Расчетный счет',
}

export const PAYMENTS_TYPES_LIST = [
  {
    name: PAYMENT_TYPES_DISPLAY.CASH,
    value: PAYMENT_TYPES.CASH,
  },
  {
    name: PAYMENT_TYPES_DISPLAY.CARD,
    value: PAYMENT_TYPES.CARD,
  },
  {
    name: PAYMENT_TYPES_DISPLAY.BILL,
    value: PAYMENT_TYPES.BILL,
  },
]
