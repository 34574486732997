export const DELIVERY_TYPE_DISPLAY = {
  SELFDELIVERY: 'Самовывоз',
  DELIVERY: 'Доставка'
}

export const DELIVERY_TYPE = {
  SELFDELIVERY: 'SELFDELIVERY',
  DELIVERY: 'DELIVERY',
  DELIVERY_TO_CLIENT: 'DELIVERY_TO_CLIENT'
}

export const DELIVERY_TYPES_LIST = [
  {
    name: DELIVERY_TYPE_DISPLAY.SELFDELIVERY, value: DELIVERY_TYPE.SELFDELIVERY
  },
  {
    name: DELIVERY_TYPE_DISPLAY.DELIVERY, value: DELIVERY_TYPE.DELIVERY
  },
  
]