<template>
  <div v-if="isOpenDropDown" :class="$style.dropdown">
    <div :class="$style.header">
      <h2>{{ title }}</h2>
    </div>
    <div :class="$style.filters">
      <slot></slot>
      <div :class="$style.actions">
        <el-button :class="$style.button" @click="$emit('submitFilters')"
          >Применить</el-button
        >
        <el-button :class="$style.button" @click="$emit('resetFilters')"
          >Сбросить</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isOpenDropDown: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" module>
.dropdown {
  padding-top: 0.75rem;
  position: absolute;
  right: 1rem;
  top: 5rem;
  background-color: $light-gray;
  color: $black;
  z-index: $z-index-drodpown;
  width: 25rem;
  border-radius: 0.25rem;
  .header {
    text-align: center;
    h2 {
      color: $black;
    }
  }
  .filters {
    padding: 1rem;
    .actions {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      gap: 2rem;
    }
    .filter {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin-right: 1rem;
      }
    }
  }
}
</style>
