export const ORDER_STATE_TYPES = {
  SUCCESSFUL: 'Successful',
  REGULAR: 'Regular',
  UNSUCCESSFUL: 'Unsuccessful',
}

export const ORDER_STATE_TYPES_SELLERS_DISPLAY = {
  PROCESSING: 'в обработке',
  ORDERED: 'заявка принята',
  RESERVED: 'в резерве',
  CONFIRMED: 'заказ подтвержден',
  ASSEMBLING: 'сборка заказа',
  ASSEMBLED: 'заказ собран',
  SELFDELIVERY: 'самовывоз готов',
  DELIVERY: 'доставляется AddWine',
  REGION_DELIVERY: 'доставляется ТК',
  SUCCESS: 'успешно реализован',
  FAILED: 'отменена заказа',
}

export const ADDEVENT_ORDER_STATUSES = {
  CREATED: 'CREATED',
  RESERVED: 'RESERVED',
  PROCESSING: 'PROCESSING',
  CONFIRMED: 'CONFIRMED',
  ASSEMBLING: 'ASSEMBLING',
  ASSEMBLED: 'ASSEMBLED',
  SELFDELIVERY: 'SELFDELIVERY',
  DELIVERY: 'DELIVERY',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
}

export const ADDEVENT_ORDER_STATUSES_DISPLAY = {
  [ADDEVENT_ORDER_STATUSES.CREATED]: 'cоздан',
  [ADDEVENT_ORDER_STATUSES.PROCESSING]: 'в обработке',
  [ADDEVENT_ORDER_STATUSES.CONFIRMED]: 'заказ подтвержден',
  [ADDEVENT_ORDER_STATUSES.ASSEMBLING]: 'сборка заказа',
  [ADDEVENT_ORDER_STATUSES.ASSEMBLED]: 'заказ собран',
  [ADDEVENT_ORDER_STATUSES.SELFDELIVERY]: 'самовывоз готов',
  [ADDEVENT_ORDER_STATUSES.DELIVERY]: 'доставляется Addevent',
  [ADDEVENT_ORDER_STATUSES.SUCCESS]: 'успешно реализован',
  [ADDEVENT_ORDER_STATUSES.FAILED]: 'отменена заказа',
  [ADDEVENT_ORDER_STATUSES.RESERVED]: 'в резерве',
}

export const ADDEVENT_ORDER_STATUSES_COLORS = {
  [ADDEVENT_ORDER_STATUSES.CREATED]: '#d6d8db',
  [ADDEVENT_ORDER_STATUSES.PROCESSING]: '#fffd80',
  [ADDEVENT_ORDER_STATUSES.CONFIRMED]: '#c0e1ff',
  [ADDEVENT_ORDER_STATUSES.ASSEMBLING]: '#99caff',
  [ADDEVENT_ORDER_STATUSES.ASSEMBLED]: '#8f3ab3',
  [ADDEVENT_ORDER_STATUSES.SELFDELIVERY]: '#ffdbdb',
  [ADDEVENT_ORDER_STATUSES.DELIVERY]: '#ffc8c8',
  [ADDEVENT_ORDER_STATUSES.SUCCESS]: '#cdfe67',
  [ADDEVENT_ORDER_STATUSES.FAILED]: '#d6d8db',
}
